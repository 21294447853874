.Reasons {
  padding: 0 2rem;
  display: flex;
}

.left-r {
  display: grid;
  /* width: 50vw; */
  grid-template-columns: auto 0.5fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.left-r :nth-child(1) {
  width: 12rem;
  height: 28.5rem;
}

.left-r :nth-child(2) {
  width: auto;
  height: 16rem;
}

.left-r :nth-child(3) {
  width: 14rem;
}

.left-r :nth-child(4) {
  width: 10rem;
}

#image-1 {
  grid-row-start: 1;
  grid-row-end: 3;
}

#image-2 {
  grid-column-start: 2;
  grid-column-end: 4;
}

.right-r {
  text-transform: uppercase;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.right-r>span{
  font-weight: bold;
  color: var(--orange);
}

.right-r div{
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.details-r{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-r>div{
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}

.details-r>div>img{
  width: 2rem;
  height: 2rem;
}

.partners{
  display: flex;
  gap: 1rem;
}

.partners>img{
  width: 2.5rem;
}

@media screen and (max-width: 768px){
  .Reasons{
    flex-direction: column;
  }

  .left-r{
    grid-auto-rows: auto;
    overflow: hidden;
  }

  .left-r>:nth-child(1){
    width: 7rem;
    height: 17rem;
  }
  
  .left-r>:nth-child(2){
    width: 15rem;
    height: 10rem;
  }
  .left-r>:nth-child(3){
    width: 7rem;
  }
  .left-r>:nth-child(4){
    width: 7rem;
    height: 6rem;
  }
}